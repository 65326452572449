import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { APP_ROUTE } from '@core/constants';
import { Observable } from 'rxjs';
import { AuthService } from '../data-access/services/auth.service';
import { RouteService } from '@core/services/route.service';

@Injectable({
	providedIn: 'root',
})
export class AuthGuard {
	constructor(
		@Inject(PLATFORM_ID) private readonly platformId: any,
		private readonly router: Router,
		private readonly auth: AuthService,
		private readonly routeService: RouteService
	) {}

	canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		const IS_LOGGED_IN = this.auth.user()?.accessToken;

		if (IS_LOGGED_IN) {
			return true;
		} else if (isPlatformBrowser(this.platformId)) {
			const CURRENT_URL = state.url;

			this.auth.openAuthDialog({
				onLogin: () => {
					this.router.navigate([CURRENT_URL]);
				},
				// Handle the scenario of the user goes to the page by using browser address bar.
				...((CURRENT_URL === this.routeService.getPreviousUrl() || !this.routeService?.getPreviousUrl()) && { navigateTo: APP_ROUTE.home }),
			});

			return false;
		}

		return false;
	}
}
